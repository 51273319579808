<template>
  <div class="navigation-main">
    <div class="navbarcontainer">
      <div class="logo">
        <img
          v-if="!isBeeBenefit"
          src="@/assets/images/newlogocolored.svg"
          alt="crm plus logo"
          class="logo-image"
        />
        <img
          v-if="isBeeBenefit"
          src="@/assets/images/logo_bzb.png"
          alt="bzb logo"
          class="logo-image"
        />
      </div>
      <div v-if="!isHideMenu" class="navmenu">
        <div
          v-for="(menuKey, index) in Object.keys(menulist)"
          :key="menulist[menuKey].lable + index"
        >
          <div
            :class="[
              'menumain',
              $route.name == menulist[menuKey].goto ||
              menulist[menuKey].submenuhover.includes($route.name)
                ? 'active'
                : '',
            ]"
            v-if="
              !menulist[menuKey].isHide && menulist[menuKey].role != 'operator'
            "
          >
            <div @click="menulistaction(menulist[menuKey].goto)">
              <span
                >{{ lbl[menulist[menuKey].lable] }}
                <span
                  v-if="menulist[menuKey].notification"
                  class="notification-badge"
                  ><img
                    src="@/assets/images/item-inactive-icon.png"
                    class="item-status-icon ml-0"
                    alt="" /></span
              ></span>
              <img
                class="arrowdown"
                v-if="menulist[menuKey].mode.includes('dropdown')"
                src="@/assets/images/Angle-down.svg"
                alt=""
              />
            </div>
            <div
              v-if="menulist[menuKey].mode.includes('dropdown')"
              class="dorpdowncontainer"
            >
              <div
                v-for="listKey in Object.keys(menulist[menuKey].submenu)"
                :key="menulist[menuKey].submenu[listKey].lable"
              >
                <div
                  v-if="
                    menulist[menuKey].submenu[listKey].goto == 'tab' &&
                    !menulist[menuKey].submenu[listKey].isHide
                  "
                  class="tab"
                >
                  {{ lbl[menulist[menuKey].submenu[listKey].lable] }}
                </div>

                <div
                  v-else-if="
                    menulist[menuKey].submenu[listKey].condition &&
                    !menulist[menuKey].submenu[listKey].isHide &&
                    menulist[menuKey].submenu[listKey].role != 'operator'
                  "
                  @click="
                    menulistaction(menulist[menuKey].submenu[listKey].goto)
                  "
                  :class="[
                    'list',
                    listKey,
                    $route.name == menulist[menuKey].submenu[listKey].goto
                      ? 'active'
                      : '',
                  ]"
                >
                  <img
                    :src="getImgUrl(menulist[menuKey].submenu[listKey].icon)"
                    alt=""
                  />
                  {{ lbl[menulist[menuKey].submenu[listKey].lable] }}
                  <span v-if="menulist[menuKey].submenu[listKey].notification">
                    <img
                      src="@/assets/images/item-inactive-icon.png"
                      class="item-status-icon ml-0 notification-badge-sub"
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isHideUser" class="userdetail">
        <div class="package-container">
          <div
            v-if="isPackage || isShowNonStandard || isBeeBenefit"
            class="d-flex action package-detail"
            @click="gotoViewPackage()"
          >
            <div
              class="notiicon"
              :class="{ 'no-action': isShowNonStandard || isFreePackage }"
            >
              <img
                :src="getPackageImage(PackageId)"
                @error="imageUrlPackageError"
                alt="package logo"
                class="package-logo"
              />
            </div>
            <div v-if="isBeeBenefit" class="name ml-3">
              <div class="package-bee-benefit-detail">
                <strong>{{ packageName }}</strong>
                <div v-if="EnableCreditPoint" class="point-detail">
                  <img
                    src="@/assets/images/dashboard/coin.svg"
                    alt="coin"
                    class="coin-img"
                  />
                  <div class="point">
                    <strong> {{ getcomma(AssignedPoint) }}</strong>
                    <span>
                      {{ '/' + getcomma(CreditPoint) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!isBeeBenefit" class="name ml-3">
              <div class="package-title">
                <strong>{{ packageName }}</strong>
                <span
                  v-if="remainingDay != null && remainingDay != 0"
                  class="remaining-day"
                >
                  <span v-if="remainingDay <= 3 && remainingDay > 1">
                    {{ '(' + remainingDay + ')' }}
                    <span>{{ lbl['selfonboard-day-left'] }}</span>
                  </span>
                  <span v-else-if="remainingDay <= 1">
                    <span class="color-red-day">
                      {{ '(' + remainingDay }}
                      {{ lbl['selfonboard-day-left'] + ')' }}</span
                    >
                  </span>
                  <span v-else>
                    {{ '(' + remainingDay }}
                    <span class="text-capitalize">{{
                      lbl['fgf-day'] + ')'
                    }}</span>
                  </span>
                </span>
                <span v-if="remainingDay != null && remainingDay != 0">
                  <span v-if="remainingDay <= 7" class="badge-package"></span>
                </span>
              </div>
            </div>
          </div>
          <div v-if="isUseOTP && !isBeeBenefit" class="sent-info">
            <img
              src="@/assets/images/ChatCircleDots.svg"
              alt="ChatCircleDots"
              class="sent-info-img"
            />
            <span class="text-capitalize">
              {{ lbl['header-view-message-detail-sms-title'] }}
              {{
                isSMSLimit
                  ? getcomma(smsLimit)
                  : lbl['header-view-message-unlimited']
              }}
            </span>
            <span v-if="emailLimit">
              |
              <img
                src="@/assets/images/EnvelopeSimple.svg"
                alt="EnvelopeSimple"
              />
              <span>
                {{ lbl['header-view-message-detail-email-title'] }}
                {{ getcomma(emailLimit) }}
              </span>
            </span>
          </div>
        </div>

        <div
          v-if="
            false && business_name && profileConfig
              ? profileConfig.ShownAnnouncement
              : false
          "
          class="notiicon action"
          @click="goto('Announcement')"
        >
          <div class="position-absolute">
            <img src="@/assets/images/notiwithoutdot.svg" alt="" />
            <img
              v-if="isNotiAnnouncement"
              src="@/assets/images/item-inactive-icon.png"
              class="announcement-icon ml-0"
              alt=""
            />
          </div>
        </div>
        <div class="userdetail-action">
          <div v-if="business_name" class="name">
            <div class="dotAfter">{{ business_name }}</div>
            <div class="dotAfter">
              {{ profilecache ? profilecache.Name : '' }}
            </div>
          </div>
          <div v-if="usercache" class="profileimagecontainer">
            <img v-if="usercache.LogoUrl" :src="usercache.LogoUrl" alt="" />
          </div>
          <div class="language">
            <span>{{ localecode.toUpperCase() }}</span>
            <img src="@/assets/images/Angle-down.svg" alt="" />
          </div>
          <div class="dorpdownusercontainer">
            <div
              v-for="menukey in Object.keys(profilemenu)"
              :key="profilemenu[menukey].lable"
            >
              <div class="tab" v-if="profilemenu[menukey].action == 'tab'">
                {{ lbl[profilemenu[menukey].lable] }}
              </div>
              <div
                class="logout tab"
                v-else-if="profilemenu[menukey].action == 'logout'"
              >
                <div
                  @click="profileaction(profilemenu[menukey].action, menukey)"
                >
                  {{ lbl[profilemenu[menukey].lable] }}
                  <img src="@/assets/images/logouticon.svg" alt="" />
                </div>
              </div>
              <div class="list" v-else>
                <div
                  @click="profileaction(profilemenu[menukey].action, menukey)"
                >
                  <img
                    :src="getImgUrl(profilemenu[menukey].icon)"
                    :class="[
                      profilemenu[menukey].action !== 'change'
                        ? 'iconprofile'
                        : '',
                    ]"
                    alt=""
                  />{{ lbl[profilemenu[menukey].lable] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="userdetail">
        <div class="userdetail-action">
          <div v-if="usercache" class="profileimagecontainer">
            <img v-if="usercache.LogoUrl" :src="usercache.LogoUrl" alt="" />
          </div>
          <div class="language">
            <span>{{ localecode.toUpperCase() }}</span>
            <img src="@/assets/images/Angle-down.svg" alt="" />
          </div>
          <div class="dorpdownusercontainer">
            <div
              v-for="menukey in Object.keys(profilemenu)"
              :key="profilemenu[menukey].lable"
            >
              <div class="tab" v-if="profilemenu[menukey].action == 'tab'">
                {{ lbl[profilemenu[menukey].lable] }}
              </div>
              <div
                class="logout tab"
                v-else-if="profilemenu[menukey].action == 'logout'"
              >
                <div
                  @click="profileaction(profilemenu[menukey].action, menukey)"
                >
                  {{ lbl[profilemenu[menukey].lable] }}
                  <img src="@/assets/images/logouticon.svg" alt="" />
                </div>
              </div>
              <div class="list" v-else>
                <div
                  @click="profileaction(profilemenu[menukey].action, menukey)"
                >
                  <img
                    :src="getImgUrl(profilemenu[menukey].icon)"
                    :class="[
                      profilemenu[menukey].action !== 'change'
                        ? 'iconprofile'
                        : '',
                    ]"
                    alt=""
                  />{{ lbl[profilemenu[menukey].lable] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hamburgermenu" @click="showsidemenu = !showsidemenu">
        <div class="mainline"></div>
        <div class="mainline"></div>
        <div class="mainline"></div>
      </div>
    </div>

    <div :class="['sidebarcontainer', showsidemenu ? 'show' : 'notshow']">
      <div class="logo">
        <img
          src="@/assets/images/newlogocolored.svg"
          alt=""
          class="logo-image"
        />
        <img
          class="closebtn"
          @click="showsidemenu = !showsidemenu"
          src="@/assets/images/close.png"
          alt=""
        />
      </div>
      <div class="sidebarlist">
        <div v-if="!isHideMenu" class="sidebaritem">
          <div
            v-for="(menuKey, index) in Object.keys(menulist)"
            :key="menulist[menuKey].lable + index"
          >
            <div
              :class="[
                'mainsidebaritem',
                $route.name == menulist[menuKey].goto ||
                menulist[menuKey].submenuhover.includes($route.name)
                  ? 'active'
                  : '',
              ]"
              v-if="
                !menulist[menuKey].isHide &&
                menulist[menuKey].role != 'operator'
              "
              @click="menulistactionsidebar(menulist[menuKey].goto, menuKey)"
            >
              <div>
                <span>{{ lbl[menulist[menuKey].lable] }}</span>
                <img
                  class="arrowdown"
                  v-if="menulist[menuKey].mode.includes('dropdown')"
                  src="@/assets/images/Angle-down.svg"
                  alt=""
                />
              </div>
            </div>

            <div :class="['dropdownsidebaritem', menulist[menuKey].mode]">
              <div
                v-if="menulist[menuKey].mode.includes('dropdown')"
                class="dorpdowncontainer"
              >
                <div
                  v-for="listKey in Object.keys(menulist[menuKey].submenu)"
                  :key="menulist[menuKey].submenu[listKey].lable"
                >
                  <div
                    v-if="
                      menulist[menuKey].submenu[listKey].condition &&
                      !menulist[menuKey].submenu[listKey].isHide &&
                      menulist[menuKey].submenu[listKey].role != 'operator'
                    "
                    @click="
                      menulistactionsidebar(
                        menulist[menuKey].submenu[listKey].goto,
                      )
                    "
                    :class="[
                      'list',
                      listKey,
                      $route.name == menulist[menuKey].submenu[listKey].goto
                        ? 'active'
                        : '',
                    ]"
                  >
                    <img
                      :src="getImgUrl(menulist[menuKey].submenu[listKey].icon)"
                      alt=""
                    />
                    {{ lbl[menulist[menuKey].submenu[listKey].lable] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="menuitem">
          <div
            v-for="menukey in Object.keys(profilemenu)"
            :key="profilemenu[menukey].lable"
          >
            <div class="tab" v-if="profilemenu[menukey].action == 'tab'">
              {{ lbl[profilemenu[menukey].lable] }}
            </div>
            <div
              class="logout tab"
              v-else-if="profilemenu[menukey].action == 'logout'"
            >
              <div @click="profileaction(profilemenu[menukey].action, menukey)">
                {{ lbl[profilemenu[menukey].lable] }}
                <img src="@/assets/images/logouticon.svg" alt="" />
              </div>
            </div>
            <div class="list" v-else>
              <div @click="profileaction(profilemenu[menukey].action, menukey)">
                <img
                  v-if="profilemenu[menukey].action == 'change'"
                  :src="getImgUrl(profilemenu[menukey].icon)"
                  alt=""
                />
                {{ lbl[profilemenu[menukey].lable] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Cache from '@/helper/Cache.js'
import Mixin from '@/mixin/Mixin'
import Account from '@/helper/AccountHelper.js'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import PackageHelper from '@/helper/PackageHelper.js'
import BzbsPdpa from '@/core/Settings/BzbsSettingsPdpa'
import BzbsAnnouncement from '@/core/Announcement/service/BzbsAnnouncement'

export default {
  name: 'Header',
  mixins: [Mixin],
  props: {
    isHideMenu: {
      type: Boolean,
      require: false,
      default: false,
    },
    isHideUser: {
      type: Boolean,
      require: false,
      default: false,
    },
    dashBoardCrmPackage: {
      type: Object,
      require: false,
      default: () => {},
    },
  },
  data: function () {
    return {
      localecode: null,
      usercache: null,
      profilecache: Cache.get('Profile'),
      business_name: '',
      host: window.location.origin,
      LoginType: '',
      StoreType: '',
      HideDashboard: false,
      HideAccountSetup: false,
      HideLineSetup: false,
      HideManageUser: false,
      HideThemeSetting: false,
      HidePointandReward: false,
      HideMemberDetail: false,
      HideUserPermission: false,
      HideSmartNotify: true,
      Hide3rdParty: true,
      showsidemenu: false,
      windowHeight: window.innerHeight,
      menulist: {
        home: {
          lable: 'main-menu-home',
          goto: 'Dashboard',
          mode: 'single',
          isHide: false,
          role: null,
          notification: false,
          submenuhover: [],
        },
        pointandreward: {
          lable: 'main-menu-plan-reward',
          goto: 'none',
          mode: 'dropdown',
          isHide: false,
          role: null,
          notification: false,
          submenuhover: ['EarnList', 'BurnList', 'FriendGetFriends'],
          submenu: {
            pointearning: {
              lable: 'plan-reward-point-earn-tab',
              icon: 'pointearnicon',
              goto: 'EarnList',
              isHide: false,
              condition: this.storetypestate == 'point',
              notification: false,
              role: null,
            },
            stampearning: {
              lable: 'plan-reward-stamp-earn-tab',
              icon: 'pointearnicon',
              goto: 'StampList',
              isHide: false,
              condition: this.storetypestate == 'stamp',
              notification: false,
              role: null,
            },
            rewardsetup: {
              lable: 'plan-reward-point-burn-tab',
              icon: 'rewardicon',
              goto: 'BurnList',
              isHide: false,
              condition: true,
              notification: false,
              role: null,
            },
            getfriends: {
              lable: 'main-menu-fgf',
              icon: 'Add-user',
              goto: 'FriendGetFriends',
              isHide: false,
              condition: true,
              notification: false,
              role: null,
            },
            tab: {
              lable: 'main-menu-quickaction',
              goto: 'tab',
              notification: false,
              role: null,
              isHide: false,
            },
            createnewreward: {
              lable: 'main-menu-createnewreward',
              icon: 'createnewrewardsicon',
              goto: 'BurnList',
              isHide: false,
              condition: true,
              notification: false,
              role: null,
            },
          },
        },
        adjustment: {
          lable: 'adjustment-label',
          goto: 'none',
          mode: 'dropdown',
          isHide: false,
          notification: false,
          role: null,
          submenuhover: ['AdjustPoint', 'AdjustSpending'],
          submenu: {
            pointadjustment: {
              lable: 'import-point-tab-label',
              icon: 'pointadjusticon',
              goto: 'AdjustPoint',
              isHide: false,
              condition: true,
              notification: false,
              role: null,
            },
            spendingadjustment: {
              lable: 'import-spending-tab-label',
              icon: 'spendingadjusticon',
              goto: 'AdjustSpending',
              isHide: false,
              condition: true,
              notification: false,
              role: null,
            },
          },
        },
        member: {
          lable: 'main-menu-user-data-manag',
          goto: 'none',
          mode: 'dropdown',
          isHide: this.crmplusrole
            ? this.crmplusrole.toLowerCase() == 'operator'
            : false,
          notification: false,
          role: null,
          submenuhover: [
            'UserCustomerManage',
            'UserLevelManage',
            'ImportUser',
            'ImportUserLog',
            'ImportUserSearch',
          ],
          submenu: {
            memberdetail: {
              lable: 'user-customer-mange-menu-label',
              icon: 'memberdetailicon',
              goto: 'UserCustomerManage',
              isHide: false,
              condition: true,
              notification: false,
              role: null,
            },
            memberlevel: {
              lable: 'usr-level-manage-tab',
              icon: 'memberlevelicon',
              goto: 'CreateUserLevel',
              isHide: false,
              condition: true,
              notification: false,
              role: null,
            },
            importmember: {
              lable: 'import-user-label',
              icon: 'importmembericon',
              goto: 'ImportUser',
              isHide: false,
              condition: true,
              notification: false,
              role: null,
            },
          },
        },
        smartassistant: {
          lable: 'main-menu-smartassistant',
          goto: 'none',
          mode: 'dropdown',
          notification: false,
          isHide: false,
          submenuhover: ['SmartNotify'],
          role: null,
          submenu: {
            targetmessage: {
              lable: 'main-menu-targetmessage',
              icon: 'targeticon',
              goto: 'SmartNotify',
              isHide: false,
              condition: true,
              notification: false,
              role: null,
            },
            noti: {
              lable: 'main-menu-noti',
              icon: 'notiicon',
              goto: 'SmartNotify',
              isHide: true,
              condition: true,
              notification: false,
              role: null,
            },
          },
        },
        setting: {
          lable: 'main-menu-setting',
          goto: 'none',
          mode: 'dropdown',
          notification: false,
          role: null,
          submenuhover: [
            'Setup',
            'RichMenu',
            'LinkSetup',
            'UserPermission',
            'Edit',
            'PdpaUpload',
            'Extensions',
          ],
          submenu: {
            account: {
              lable: 'main-menu-company-setup',
              icon: 'accounticon',
              goto: 'AccountSetting',
              isHide: false,
              condition: true,
              notification: false,
              role: null,
            },
            line: {
              lable: 'main-menu-line',
              icon: 'lineicon',
              goto: 'RichMenu',
              isHide: false,
              condition: this.includeslogintype('line'),
              notification: false,
              role: null,
            },
            email: {
              lable: 'main-menu-link-setup',
              icon: 'Mail',
              goto: 'LinkSetup',
              isHide: false,
              condition:
                (this.includeslogintype('device') &&
                  !this.includeslogintype('line')) ||
                this.includeslogintype('email') ||
                this.includeslogintype('facebook'),
              notification: false,
              role: null,
            },
            user: {
              lable: 'main-menu-user',
              icon: 'userpermissionicon',
              goto: 'UserPermission',
              isHide: false,
              condition: true,
              notification: false,
              role: null,
            },
            theme: {
              lable: 'main-menu-theme',
              icon: 'themeicon',
              goto: 'Theme',
              isHide: false,
              condition: true,
              notification: false,
              role: null,
            },
            pdpa: {
              lable: 'member-privacy-menu',
              icon: 'privacyicon',
              goto: 'PdpaUpload',
              isHide: false,
              condition: true,
              notification: false,
              role: null,
            },
            tab: {
              lable: 'main-menu-other',
              goto: 'tab',
              notification: false,
              role: null,
              isHide: false,
            },
            extension: {
              lable: 'extensions',
              icon: 'extensionicon',
              goto: 'Extensions',
              isHide: false,
              condition: true,
              notification: false,
              role: null,
            },
          },
          isHide: false,
        },
      },
      profilemenu: {
        myprofile: {
          lable: 'main-menu-myprofile',
          icon: 'profileicon',
          action: 'configprofile',
          isHide: false,
        },
        tutorial: {
          lable: 'main-menu-tutorials',
          icon: 'movie',
          action: 'tutorial',
          isHide: false,
        },
        usermanul: {
          lable: 'main-menu-usermanul',
          icon: 'usernamaulicon',
          action: 'usermanual',
          isHide: false,
        },
        language: {
          lable: 'main-menu-language',
          action: 'tab',
        },
        en: {
          lable: 'main-menu-en',
          icon: 'en',
          action: 'change',
          isHide: false,
        },
        th: {
          lable: 'main-menu-th',
          icon: 'th',
          action: 'change',
          isHide: false,
        },
        ph: {
          lable: 'main-menu-ph',
          icon: 'ph',
          action: 'change',
          isHide: false,
        },
        logout: {
          lable: 'main-menu-log-off',
          icon: 'logouticon',
          action: 'logout',
          isHide: false,
        },
      },
      packageName: '',
      PackageId: '',
      remainingDay: '',
      isPackage: false,
      isShowNonStandard: false,
      isBeeBenefit: false,
      crmPackage: {},
      isFreePackage: false,
      profileConfig: {
        ShownAnnouncement: false,
      },
      isNotiAnnouncement: false,
      isUseOTP: false,
      isSMSLimit: false,
      smsLimit: 0,
      emailLimit: 0,
      appId: null,
      EnableCreditPoint: false,
      CreditPoint: 0,
      AssignedPoint: 0,
    }
  },
  watch: {
    dashBoardCrmPackage: {
      deep: true,
      handler(e) {
        let configSelfOnBoard = false
        try {
          let parse = JSON.parse(localStorage.getItem('bzbsCRMConfig'))
          if (parse) {
            configSelfOnBoard = parse.buzzebeesConfigSelfOnBoard
              ? parse.buzzebeesConfigSelfOnBoard
              : false
          } else {
            configSelfOnBoard = Vue.bzbsConfig.businessRule.open.package
          }
        } catch (e) {
          configSelfOnBoard = Vue.bzbsConfig.businessRule.open.package
        }

        if (configSelfOnBoard) {
          if (e) {
            if (e.RequirePackage && e.HasSelectedPackage) {
              if (e.PackageId && e.PackageId.includes('benefit')) {
                this.isBeeBenefit = true
                this.PackageId = this.crmPackage.PackageId
                this.packageName = this.crmPackage.PackageName
                this.profilemenu = {
                  myprofile: {
                    lable: 'main-menu-myprofile',
                    icon: 'profileicon',
                    action: 'configprofile',
                    isHide: false,
                  },
                  language: {
                    lable: 'main-menu-language',
                    action: 'tab',
                  },
                  en: {
                    lable: 'main-menu-en',
                    icon: 'en',
                    action: 'change',
                    isHide: false,
                  },
                  th: {
                    lable: 'main-menu-th',
                    icon: 'th',
                    action: 'change',
                    isHide: false,
                  },
                  ph: {
                    lable: 'main-menu-ph',
                    icon: 'ph',
                    action: 'change',
                    isHide: false,
                  },
                  logout: {
                    lable: 'main-menu-log-off',
                    icon: 'logouticon',
                    action: 'logout',
                    isHide: false,
                  },
                }
              }
              if (e.IsStandardPackage) {
                this.isPackage = true
                this.packageName = e.PackageName
                this.PackageId = e.PackageId
                this.remainingDay = e.RemainingDay
              } else {
                if (
                  e.PackageId == 'ezycoupon' ||
                  e.PackageId == 'corporate' ||
                  e.PackageId == 'basicplus' ||
                  e.PackageId.includes('basic') ||
                  e.PackageId.includes('premium')
                ) {
                  this.isShowNonStandard = true
                  this.packageName = e.PackageName
                  this.PackageId = e.PackageId
                  this.remainingDay = e.RemainingDay
                }
              }
            }
            if (e.IsFreePackage) {
              this.isFreePackage = true
            } else {
              this.isFreePackage = false
            }
          }
        }
      },
    },
  },
  computed: {
    logintypestate() {
      return this.LoginType ? this.LoginType.toLowerCase() : null
    },
    storetypestate() {
      return this.StoreType
    },
    crmplusrole() {
      return Cache.get('CRMUser') ? Cache.get('CRMUser').CrmPlusRole : null
    },
  },
  created() {
    if (Vue.bzbsConfig.businessRule.webFor.PH) {
      this.profilemenu = {
        myprofile: {
          lable: 'main-menu-myprofile',
          icon: 'profileicon',
          action: 'configprofile',
          isHide: false,
        },
        usermanul: {
          lable: 'main-menu-usermanul',
          icon: 'usernamaulicon',
          action: 'usermanual',
          isHide: false,
        },
        language: {
          lable: 'main-menu-language',
          action: 'tab',
        },
        en: {
          lable: 'main-menu-en',
          icon: 'en',
          action: 'change',
          isHide: false,
        },
        th: {
          lable: 'main-menu-th',
          icon: 'th',
          action: 'change',
          isHide: false,
        },
        ph: {
          lable: 'main-menu-ph',
          icon: 'ph',
          action: 'change',
          isHide: false,
        },
        logout: {
          lable: 'main-menu-log-off',
          icon: 'logouticon',
          action: 'logout',
          isHide: false,
        },
      }
    }
    this.init()
    console.log(this.$route.name)
  },
  mounted() {
    this.getProfileData()
    this.onResize()
    this.showsidemenu = false
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    if (this.isHideMenu == true) {
      this.profilemenu = {
        language: {
          lable: 'main-menu-language',
          action: 'tab',
        },
        en: {
          lable: 'main-menu-en',
          icon: 'en',
          action: 'change',
          isHide: false,
        },
        th: {
          lable: 'main-menu-th',
          icon: 'th',
          action: 'change',
          isHide: false,
        },
        ph: {
          lable: 'main-menu-ph',
          icon: 'ph',
          action: 'change',
          isHide: false,
        },
        logout: {
          lable: 'main-menu-log-off',
          icon: 'logouticon',
          action: 'logout',
          isHide: false,
        },
      }
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    includeslogintype(type) {
      if (this.LoginType) {
        return this.LoginType.toLowerCase().includes(type)
      } else {
        return false
      }
    },
    init() {
      this.usercache = Cache.get('CRMUser')
      this.crmPackage = Account.getCachePackage()

      //Check Role
      let crmplusrole = this.crmplusrole ? this.crmplusrole.toLowerCase() : null
      this.menulist.pointandreward.role = crmplusrole
      this.menulist.adjustment.submenu.pointadjustment.role = crmplusrole
      this.menulist.adjustment.submenu.spendingadjustment.role = crmplusrole
      this.menulist.smartassistant.role = crmplusrole
      this.menulist.member.submenu.memberlevel.role = crmplusrole
      this.menulist.member.submenu.memberdetail.role = crmplusrole

      Account.apiProfileConfig().then(() => {
        this.profileConfig = Account.getCacheProfileConfig()
        if (Cache.get('Profile').terminate) {
          this.$router.push({
            name: 'Terminate',
          })
        }
        if (this.profileConfig.ShownAnnouncement) {
          this.checkNotiAnnouncement()
        }
      })

      if (this.usercache) {
        if (this.usercache.LogoUrl)
          this.usercache.LogoUrl =
            this.usercache.LogoUrl + '?v=' + new Date().getTime()
        this.business_name = this.usercache.BusinessName
          ? this.usercache.BusinessName
          : ''
      }
      this.getCRMPlusProfile().then(() => {
        if (
          this.menulist.setting.submenu.account.notification ||
          this.menulist.setting.submenu.pdpa.notification
        ) {
          this.menulist.setting.notification = true
        } else {
          this.menulist.setting.notification = false
        }
      })
      let configSelfOnBoard = false
      try {
        let parse = JSON.parse(localStorage.getItem('bzbsCRMConfig'))
        if (parse) {
          configSelfOnBoard = parse.buzzebeesConfigSelfOnBoard
            ? parse.buzzebeesConfigSelfOnBoard
            : false
        } else {
          configSelfOnBoard = Vue.bzbsConfig.businessRule.open.package
        }
      } catch (e) {
        configSelfOnBoard = Vue.bzbsConfig.businessRule.open.package
      }

      if (configSelfOnBoard) {
        if (this.crmPackage) {
          if (
            this.crmPackage.RequirePackage &&
            this.crmPackage.HasSelectedPackage
          ) {
            if (
              this.crmPackage.PackageId &&
              this.crmPackage.PackageId.includes('benefit')
            ) {
              this.isBeeBenefit = true
              this.PackageId = this.crmPackage.PackageId
              this.packageName = this.crmPackage.PackageName
              this.profilemenu = {
                myprofile: {
                  lable: 'main-menu-myprofile',
                  icon: 'profileicon',
                  action: 'configprofile',
                  isHide: false,
                },
                language: {
                  lable: 'main-menu-language',
                  action: 'tab',
                },
                en: {
                  lable: 'main-menu-en',
                  icon: 'en',
                  action: 'change',
                  isHide: false,
                },
                th: {
                  lable: 'main-menu-th',
                  icon: 'th',
                  action: 'change',
                  isHide: false,
                },
                ph: {
                  lable: 'main-menu-ph',
                  icon: 'ph',
                  action: 'change',
                  isHide: false,
                },
                logout: {
                  lable: 'main-menu-log-off',
                  icon: 'logouticon',
                  action: 'logout',
                  isHide: false,
                },
              }
            }
            if (this.crmPackage.IsStandardPackage) {
              this.isPackage = true
              this.packageName = this.crmPackage.PackageName
              this.PackageId = this.crmPackage.PackageId
              this.remainingDay = this.crmPackage.RemainingDay
            } else {
              if (
                this.crmPackage.PackageId == 'ezycoupon' ||
                this.crmPackage.PackageId == 'corporate' ||
                this.crmPackage.PackageId == 'basicplus' ||
                this.crmPackage.PackageId.includes('basic') ||
                this.crmPackage.PackageId.includes('premium')
              ) {
                this.isShowNonStandard = true
                this.packageName = this.crmPackage.PackageName
                this.PackageId = this.crmPackage.PackageId
                this.remainingDay = this.crmPackage.RemainingDay
              }
            }
          }
          if (this.crmPackage && this.crmPackage.IsFreePackage) {
            this.isFreePackage = true
          } else {
            this.isFreePackage = false
          }
        }
      }
    },

    getPackageImage(packageId) {
      if (packageId && packageId.includes('benefit')) {
        return (
          Vue.bzbsConfig.bzbsBlobUrl +
          `/config/crmplus/package/logo_bee_benefit.jpg` +
          `?v=` +
          new Date().getTime()
        )
      } else {
        return (
          Vue.bzbsConfig.bzbsBlobUrl +
          `/config/crmplus/package/logo_${packageId}.jpg` +
          `?v=` +
          new Date().getTime()
        )
      }
    },
    imageUrlPackageError(event) {
      event.target.src = require('@/assets/images/selfonboard/package.svg')
    },
    //#region main
    getProfileData() {
      BzbsProfile.getProfileName()
        .then(res => {
          this.profilecache = res.data
        })
        .catch(err => console.log(err))
    },
    getCRMPlusProfile() {
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.appId = res.data.AppId
            this.checkAccountSettup(res.data)
            this.checkPdpaUploadLog()
            this.EnableCreditPoint = res.data.EnableCreditPoint
              ? res.data.EnableCreditPoint
              : false
            this.CreditPoint = res.data.CreditPoint ? res.data.CreditPoint : 0
            this.AssignedPoint = res.data.AssignedPoint
              ? res.data.AssignedPoint
              : 0
            this.isSMSLimit = res.data.LimitSms
            this.isUseOTP = res.data.UseOTP
            this.smsLimit = res.data.LimitSmsCurrent
            this.emailLimit = res.data.LimitEmailCurrent
            this.HideDashboard = res.data.HideDashboard
            this.HideAccountSetup = res.data.HideAccountSetup
            this.HideLineSetup = res.data.HideLineSetup
            this.HideManageUser = res.data.HideManageUser
            this.HideThemeSetting = res.data.HideThemeSetting
            this.HideMemberDetail = res.data.HideMemberDetail
            this.HidePointandReward = res.data.HidePointandReward
            this.Hide3rdParty = res.data.Hide3rdPartyMenu

            this.LoginType = res.data.LoginType
              ? res.data.LoginType.toLowerCase()
              : null
            this.StoreType = res.data.StoreType
            this.business_name = res.data.BusinessName

            this.menulist.pointandreward.submenu.pointearning.condition =
              res.data.StoreType == 'point'
            this.menulist.pointandreward.submenu.stampearning.condition =
              res.data.StoreType == 'stamp'

            this.menulist.home.isHide = this.getbooleantype(
              res.data.HideDashboard,
            )
            this.menulist.setting.submenu.line.isHide = this.getbooleantype(
              res.data.HideLineSetup,
            )
            this.menulist.setting.submenu.theme.isHide = this.getbooleantype(
              res.data.HideThemeSetting,
            )

            this.menulist.pointandreward.submenu.getfriends.isHide =
              this.getbooleantype(res.data.HideFriendGetFriends)
            this.menulist.smartassistant.submenu.targetmessage.isHide =
              this.getbooleantype(res.data.HideTargetedMessage)

            //Handle Menu for Bee's Benefit
            if (this.isBeeBenefit) {
              this.menulist.pointandreward.isHide = true
              this.menulist.adjustment.submenu.pointadjustment.isHide = false
              this.menulist.adjustment.submenu.spendingadjustment.isHide = true
              this.menulist.member.submenu.memberlevel.isHide = true
              // this.menulist.setting.submenu.account.isHide = true
              this.menulist.setting.submenu.account.goto =
                'AccountSettingBeeBenefit'
              this.menulist.setting.submenu.pdpa.isHide = true
              this.menulist.setting.submenu.user.isHide = true
              this.menulist.setting.submenu.extension.isHide = true
              this.menulist.setting.submenu.tab.isHide = true
              this.menulist.setting.submenu.email.condition = false
              this.menulist.setting.submenu.line.condition = false
            } else {
              this.menulist.pointandreward.isHide = this.getbooleantype(
                res.data.HidePointandReward,
              )
              this.menulist.adjustment.submenu.pointadjustment.isHide = !(
                this.getbooleantype(res.data.IsAllowImportBatch) &&
                res.data.StoreType == 'point'
              )
              this.menulist.adjustment.submenu.spendingadjustment.isHide = !(
                this.getbooleantype(res.data.ShowAdjustSpending) &&
                res.data.StoreType == 'point'
              )

              if (
                this.menulist.adjustment.submenu.pointadjustment.isHide &&
                this.menulist.adjustment.submenu.spendingadjustment.isHide
              ) {
                this.menulist.adjustment.isHide = true
              }
              this.menulist.member.submenu.memberlevel.isHide =
                this.getbooleantype(!res.data.HasUserlevel)
              this.menulist.setting.submenu.account.isHide =
                this.getbooleantype(res.data.HideAccountSetup)
              this.menulist.setting.submenu.user.isHide = this.getbooleantype(
                res.data.HideManageUser,
              )
              this.menulist.setting.submenu.extension.isHide =
                this.getbooleantype(res.data.Hide3rdPartyMenu)
              this.menulist.setting.submenu.email.condition = res.data.LoginType
                ? this.includeslogintype('email') ||
                  this.includeslogintype('facebook') ||
                  (this.includeslogintype('device') &&
                    !this.includeslogintype('line'))
                : false
              this.menulist.setting.submenu.line.condition =
                this.handleShowRichMenu(res.data)
            }

            this.menulist.member.submenu.memberdetail.isHide =
              this.getbooleantype(res.data.HideMemberDetail)

            this.menulist.member.submenu.importmember.isHide =
              this.getbooleantype(res.data.HideImportUserMenu)

            let crmplusrole = this.crmplusrole
              ? this.crmplusrole.toLowerCase()
              : null

            if (
              this.getbooleantype(res.data.HideImportUserMenu) &&
              this.getbooleantype(!res.data.HasUserlevel) &&
              this.getbooleantype(res.data.HideMemberDetail)
            ) {
              this.menulist.member.isHide = true
            } else if (
              this.getbooleantype(res.data.HideImportUserMenu) &&
              crmplusrole == 'operator'
            ) {
              this.menulist.member.isHide = true
            } else {
              this.menulist.member.isHide = false
            }

            if (this.isBeeBenefit) {
              this.menulist.smartassistant.isHide = true
            } else {
              if (
                this.menulist.smartassistant.submenu.targetmessage.isHide &&
                this.menulist.smartassistant.submenu.noti.isHide
              ) {
                this.menulist.smartassistant.isHide = true
              } else {
                this.menulist.smartassistant.isHide = false
              }
            }
            resolve(res.data)
          })
          .catch(error => {
            console.log('getCRMPlusProfile error', error)
            resolve(error)
          })
      })
    },
    handleShowRichMenu(data) {
      if (
        this.includeslogintype('line') &&
        (data.LiffLanding || data.LiffId) &&
        data.AppId
      ) {
        return true
      } else {
        return false
      }
    },
    getImgUrl(pic) {
      return require('@/assets/images/' + pic + '.svg')
    },
    getbooleantype(data) {
      if (data == 'True' || data == true || data == 'true') {
        return true
      } else {
        return false
      }
    },
    profileaction(action, type) {
      if (action == 'change') {
        this.switchLang(type)
      } else if (action == 'logout') {
        this.logout()
      } else {
        this.menulistaction(action)
      }
    },
    menulistaction(path) {
      if (path !== 'none') {
        if (path.split('_')[1] == 'old') {
          window.location = this.host + path.split('_')[0]
        } else {
          Account.bzbsAnalyticTracking(
            'header_menu',
            'menu',
            'click_' + path,
            'on click',
          )
          this.goto(path)
        }
      }
    },
    menulistactionsidebar(path, menukey) {
      if (path !== 'none') {
        if (path.split('_')[1] == 'old') {
          window.location = this.host + path.split('_')[0]
        } else {
          Account.bzbsAnalyticTracking(
            'header_menu',
            'menu',
            'click_' + path,
            'on click',
          )
          this.goto(path)
        }
      } else {
        if (this.menulist[menukey].mode == 'dropdown') {
          this.menulist[menukey].mode = 'dropdownopen'
        } else if (this.menulist[menukey].mode == 'dropdownopen') {
          this.menulist[menukey].mode = 'dropdown'
        }
      }
    },
    onResize() {
      if (window.innerWidth > 1172) {
        this.showsidemenu = false
      }
    },
    async switchLang(code) {
      Account.bzbsAnalyticTracking(
        'header_menu',
        'menu',
        'click_change_language',
        'on click',
      )
      this.updateLanguage(code)
      await this.setLang(code)
      this.$router.go()
    },
    updateLanguage(code) {
      var locale
      switch (code) {
        case 'th':
          locale = 1054
          break
        case 'en':
          locale = 1033

          break
        case 'ph':
          locale = 13321

          break
        default:
          locale = 1033
      }
      return new Promise(resolve => {
        BzbsProfile.updateLanguage(locale)
          .then(res => {
            var CRMUserCookie = Cache.get('CRMUser')
            CRMUserCookie.Token = res.data.data
            Cache.set('CRMUser', CRMUserCookie)
            resolve(res.data)
          })
          .catch(err => {
            console.log(err)
            resolve(err)
          })
      })
    },
    logout() {
      Account.bzbsAnalyticTracking(
        'header_menu',
        'menu',
        'click_logout',
        'on click',
      )
      Account.setLogout()
      this.$router.push({ name: 'Login' })
    },
    gotoAccountSetup() {
      Account.bzbsAnalyticTracking(
        'header_menu',
        'menu',
        'click_account_setup',
        'on click',
      )
      this.$router.push({ name: 'AccountSetting' })
    },
    gotoViewPackage() {
      Account.bzbsAnalyticTracking(
        'view_package',
        'package',
        'click_view_package',
        'on click',
      )
      if (
        this.crmPackage &&
        (!this.crmPackage.IsStandardPackage || this.crmPackage.IsFreePackage)
      ) {
        return
      } else {
        if (PackageHelper.filterPackageByPremium(this.crmPackage.PackageId)) {
          this.$router.push('/View/Package/Premium')
        } else {
          this.$router.push('/View/Package/Basic')
        }
      }
    },
    getcomma(number) {
      try {
        if (number) {
          return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        } else {
          return 0
        }
      } catch {
        return number
      }
    },
    //#endregion main

    //#region Notification
    checkPdpaUploadLog() {
      this.menulist.setting.notification = false
      this.menulist.setting.submenu.pdpa.notification = false
      if (this.isBeeBenefit) {
        this.menulist.setting.notification = false
        this.menulist.setting.submenu.pdpa.notification = false
      } else {
        if (this.appId) {
          this.getTotalPdpaUploadLog('terms').then(terms => {
            if (terms == 0) {
              this.showNotificationPDPA()
            } else {
              this.getTotalPdpaUploadLog('privacy').then(privacy => {
                if (privacy == 0) {
                  this.showNotificationPDPA()
                } else {
                  this.getTotalPdpaUploadLog('marketing').then(marketing => {
                    if (marketing == 0) {
                      this.showNotificationPDPA()
                    }
                  })
                }
              })
            }
          })
        }
      }
    },
    checkAccountSettup(data) {
      if (data.LoginType.toLowerCase().includes('line')) {
        if ((data.LiffLanding || data.LiffId) && data.AppId) {
          this.menulist.setting.submenu.account.notification = false
        } else {
          this.menulist.setting.submenu.account.notification = true
        }
      } else {
        if (data.AppId) {
          this.menulist.setting.submenu.account.notification = false
        } else {
          this.menulist.setting.submenu.account.notification = true
        }
      }
    },
    getTotalPdpaUploadLog(type) {
      return new Promise(resolve => {
        BzbsPdpa.getImportConsentLog(type, 10, 0)
          .then(res => {
            resolve(res.data.total)
          })
          .catch(error => {
            resolve(error)
          })
      })
    },
    showNotificationPDPA() {
      this.menulist.setting.notification = true
      this.menulist.setting.submenu.pdpa.notification = true
    },
    checkNotiAnnouncement() {
      this.isNotiAnnouncement = false
      return new Promise(resolve => {
        BzbsAnnouncement.apiAnnouncementUnread()
          .then(res => {
            if (res.data.success) {
              if (res.data.data.Count) {
                if (res.data.data.Count > 0) {
                  this.isNotiAnnouncement = true
                }
              }
            }
            resolve(res.data)
          })
          .catch(error => {
            resolve(error)
          })
      })
    },
    //#endregion Notification
  },
}
</script>

<style lang="scss">
$menu-item-color: #b9b9b9;
$menu-item-active-color: #fff;

.header-container {
  padding-bottom: 40px;
  font-size: 1.5em;
  font-weight: bold;
}
.navigation-container {
  background-color: transparent;
  color: white;
  white-space: nowrap;
}
/* Left Logo */

.navigation-logo-container {
  margin: 5px 0px;
  img {
    width: 60px;
    height: 60px;
  }
  .box-language {
    display: inline-block;
    img.flag-language {
      opacity: 0.5;
      filter: alpha(opacity=50); /* For IE8 and earlier */
      width: 30px;
      height: 20px;
      cursor: pointer;
      &.active {
        opacity: 1;
        filter: alpha(opacity=100); /* For IE8 and earlier */
        width: 45px;
        height: 30px;
        border: 1px #fff solid;
        cursor: auto;
      }
      + a {
        margin-left: 4px;
      }
    }
    a {
      + img {
        margin-left: 4px;
      }
    }
  }
}

/* Menu Navigation */
.navigation-menu-container {
  line-height: 50px;
  text-align: center;
}

.navigation-menu-container a.menu-item {
  color: $menu-item-color;
  text-decoration: none;
  margin: 0px 5px;
  padding: 10px 5px;
  position: relative;
}

.navigation-menu-container a.menu-item:after {
  position: absolute;
  left: 0px;
  width: 100%;
  height: 5px;
  border-radius: 2px;
  background: $menu-item-active-color;
  content: '';
  opacity: 0;
  bottom: 0px;
  transform: translateY(-10px);
  transition: all 0.3s;
}

.navigation-menu-container a.menu-item.active {
  color: $menu-item-active-color;
}

.navigation-menu-container a.menu-item.active:after {
  opacity: 1;
  position: absolute;
  left: 0px;
  width: 100%;
  height: 5px;
  background: $menu-item-active-color;
  color: $menu-item-active-color;
  content: '';
  transform: translateY(0px);
  border-radius: 2px;
}

.navigation-menu-container a.menu-item:hover {
  color: $menu-item-active-color;
}

.navigation-menu-container a.menu-item:hover:after {
  opacity: 1;
  transform: translateY(0px);
}

/* Profile */
.navigation-profile-container {
  margin-top: 5px;
  width: 100%;
  display: inline-flex;
  text-align: right;
  direction: rtl;
  .navigation-profile-details {
    vertical-align: middle;
    padding-right: 15px;
    padding-top: 3%;
    a {
      color: white;
      display: block;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: white;
        text-decoration: none;
      }
    }
    @media (device-pixel-ratio: 1.25), (-webkit-device-pixel-ratio: 1.25) {
      padding-top: 5%;
    }

    @media (device-pixel-ratio: 1.5), (-webkit-device-pixel-ratio: 1.5) {
      padding-top: 5%;
    }

    @media (min-width: 1366px) {
      padding-top: 3%;
    }

    @media (min-width: 1366px) and (device-pixel-ratio: 1.25),
      (-webkit-device-pixel-ratio: 1.25) {
      padding-top: 3%;
    }
  }

  .navigation-profile-picture {
    display: table-cell;
    vertical-align: middle;
    img {
      width: 60px;
      height: 60px;
      cursor: pointer;
    }
  }
}

.w3-dropdown-click,
.w3-dropdown-hover {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.w3-dropdown-hover:hover .w3-dropdown-content {
  display: block;
}

.w3-dropdown-hover:first-child,
.w3-dropdown-click:hover {
  //background-color: #ccc;
  color: #fff;
}

.w3-dropdown-hover:hover > .w3-button:first-child,
.w3-dropdown-click:hover > .w3-button:first-child {
  //background-color: #ccc;
  color: #fff;
}

.w3-btn,
.w3-button,
.w3-button-item {
  border: none;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: #b9b9b9;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
}

.w3-btn,
.w3-button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.w3-button {
  display: block;
}

.w3-button:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.w3-dropdown-hover:hover > .w3-button:first-child,
.w3-dropdown-click:hover > .w3-button:first-child {
  //background-color: #ccc;
  color: #fff;
}

.w3-bar .w3-button {
  white-space: normal;
}
.w3-bar-block {
  .w3-dropdown-hover .w3-button,
  .w3-dropdown-click .w3-button {
    width: 100%;
    text-align: left;
    padding: 8px 16px;
  }
  .w3-bar-item {
    padding: 8px 16px;
    float: left;
    width: auto;
    border: none;
    display: block;
    line-height: 20px;
    outline: 0;
  }
}
@media (max-width: 600px) {
  .w3-modal-content {
    margin: 0 10px;
    width: auto !important;
  }

  .w3-modal {
    padding-top: 30px;
  }

  .w3-dropdown-hover.w3-mobile .w3-dropdown-content,
  .w3-dropdown-click.w3-mobile .w3-dropdown-content {
    position: relative;
  }

  .w3-hide-small {
    display: none !important;
  }

  .w3-mobile {
    display: block;
    width: 100% !important;
  }

  .w3-bar-item.w3-mobile,
  .w3-dropdown-hover.w3-mobile,
  .w3-dropdown-click.w3-mobile {
    text-align: center;
  }

  .w3-dropdown-hover.w3-mobile,
  .w3-dropdown-hover.w3-mobile .w3-btn,
  .w3-dropdown-hover.w3-mobile .w3-button,
  .w3-dropdown-click.w3-mobile,
  .w3-dropdown-click.w3-mobile .w3-btn,
  .w3-dropdown-click.w3-mobile .w3-button {
    width: 100%;
  }
}

.w3-button:hover {
  color: #fff !important;
  //background-color: #ccc !important
}

.w3-button-item:hover {
  color: #000 !important;
  text-decoration: none !important;
}

.w3-dropdown-content {
  cursor: auto;
  color: rgb(184, 184, 184);
  background-color: #fff;
  display: none;
  position: absolute;
  min-width: 200px;
  margin: 0;
  padding: 0;
  z-index: 10;
}

.w3-bar-block .w3-dropdown-hover .w3-dropdown-content,
.w3-bar-block .w3-dropdown-click .w3-dropdown-content {
  min-width: 100%;
}

.w3-bar-block .w3-dropdown-hover,
.w3-bar-block .w3-dropdown-click {
  width: 100%;
}

.w3-bar-block .w3-dropdown-hover .w3-dropdown-content,
.w3-bar-block .w3-dropdown-click .w3-dropdown-content {
  min-width: 100%;
}

.w3-bar-block .w3-dropdown-hover .w3-button,
.w3-bar-block .w3-dropdown-click .w3-button {
  width: 100%;
  text-align: left;
  padding: 8px 16px;
}

.w3-bar-block .w3-bar-item {
  width: 100%;
  display: block;
  padding: 8px 16px;
  text-align: left;
  border: none;
  white-space: normal;
  float: none;
  outline: 0;
}

.w3-bar-block.w3-center .w3-bar-item {
  text-align: center;
}

.w3-block {
  display: block;
  width: 100%;
}

.w3-card-4,
.w3-hover-shadow:hover {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19);
}

@media only screen and (max-device-width: 768px) {
  .navigation-menu-container {
    overflow-x: scroll;
  }
}

.navigation-main {
  @mixin transition {
    @for $i from 1 through 50 {
      &:nth-child(#{$i}) {
        transition: transform
          1s
          #{$i *
          0.08}s
          cubic-bezier(0.29, 1.4, 0.44, 0.96);
      }
    }
  }

  @mixin alpha-attribute($attribute, $color, $background) {
    $percent: alpha($color) * 100%;
    $opaque: opacify($color, 1);
    $solid-color: mix($opaque, $background, $percent);
    #{$attribute}: $solid-color;
    #{$attribute}: $color;
  }

  html {
    font-family: 'Lato', sans-serif;
  }

  body {
    margin: 0;
    overflow-x: hidden;
    height: 100vh;
    width: 100vw;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  $main: #1184f0;
  $text: #fefefe;
  $contrast: darken($main, 10%);
  .msg {
    font-family: 'Pacifico', cursive;
    font-size: 8vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    align-items: center;
    color: $text;
    background-color: $main;
  }

  .features {
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
    text-transform: uppercase;
    li {
      width: 100%;
      text-align: center;
      padding: 0.25em;
    }
  }

  .drawer-list {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    transform: translate(100vw, 0);
    z-index: 99;
    -ms-transform: translatex(-100vw);
    box-sizing: border-box;
    pointer-events: none;
    padding-top: 74px;
    transition: width 475ms ease-out, transform 450ms ease,
      border-radius 0.8s 0.1s ease;
    border-bottom-left-radius: 100vw;
    background-color: rgb(0 0 0 / 85%);
    @media (min-width: 768px) {
      width: 40vw;
    }
    ul {
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 0px 0px 20px 0px;
      overflow: auto;
      overflow-x: hidden;
      pointer-events: auto;
    }
    li {
      list-style: none;
      pointer-events: auto;
      white-space: nowrap;
      box-sizing: border-box;
      transform: translatex(100vw);
      /* ie workaround */
      -ms-transform: translatex(-100vw);
      &:last-child {
        margin-bottom: 2em;
      }
      a {
        text-decoration: none;
        color: $text;
        text-align: center;
        display: block;
        padding: 1rem;
        font-size: calc(24px - 0.5vw);
        @media (min-width: 768px) {
          text-align: right;
          padding: 0.5rem;
        }
        &:hover {
          cursor: pointer;
          @include alpha-attribute('background-color', rgba($main, 0.5), white);
        }
      }
    }
  }

  input.hamburger {
    display: none;
    &:checked {
      & ~ .drawer-list {
        transform: translatex(0);
        border-bottom-left-radius: 0;
        position: fixed;
        li {
          transform: translatex(0);
          @include transition;
          a {
            padding-right: 15px;
          }
        }
      }
      & ~ label {
        position: fixed;
        > i {
          background-color: transparent;
          transform: rotate(90deg);
          &:before {
            transform: translate(-50%, -50%) rotate(315deg);
          }
          &:after {
            transform: translate(-50%, -50%) rotate(-315deg);
          }
        }
        close {
          color: $text;
          width: 100%;
        }
        open {
          color: rgba(0, 0, 0, 0);
          width: 0;
        }
      }
    }
  }

  label.hamburger {
    z-index: 999;
    position: absolute;
    display: block;
    height: 50px;
    width: 50px;
    &:hover {
      cursor: pointer;
    }
    text {
      close,
      open {
        text-transform: uppercase;
        font-size: 0.8em;
        text-align: center;
        position: absolute;
        transform: translateY(50px);
        text-align: center;
        overflow: hidden;
        transition: width 0.25s 0.35s, color 0.45s 0.35s;
      }
      close {
        color: rgba(0, 0, 0, 0);
        right: 0;
        width: 0;
      }
      open {
        color: $text;
        width: 100%;
      }
    }
    > i {
      position: absolute;
      width: 100%;
      height: 2px;
      top: 50%;
      background-color: $text;
      pointer-events: auto;
      transition-duration: 0.35s;
      transition-delay: 0.35s;
      &:before,
      &:after {
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        left: 50%;
        background-color: $text;
        content: '';
        transition: transform 0.35s;
        transform-origin: 50% 50%;
      }
      &:before {
        transform: translate(-50%, -14px);
      }
      &:after {
        transform: translate(-50%, 14px);
      }
    }
  }

  label.hamburger {
    position: absolute;
    top: 10px;
    right: 15px;
  }

  body {
    scrollbar-base-color: rgb(19, 138, 114);
    scrollbar-3dlight-color: #fff;
    scrollbar-highlight-color: #1abc9c;
    scrollbar-track-color: #fff;
    scrollbar-arrow-color: #1abc9c;
    scrollbar-shadow-color: #1abc9c;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .icon {
    display: inline-block;
    width: 5vw;
    height: 4vw;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #e3e3e3;
  }

  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover {
    color: #000000 !important;
  }

  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: initial;
  }

  .ant-menu-item:active,
  .ant-menu-item-active:active,
  .ant-menu-item-selected:active {
    color: rgba(0, 0, 0, 0.65) !important;
  }

  .ant-menu-selected {
    color: rgba(0, 0, 0, 0.65) !important;
  }

  .ant-menu-submenu-selected {
    color: rgba(0, 0, 0, 0.65) !important;
  }

  .ant-menu-item-selected {
    color: rgba(0, 0, 0, 0.65) !important;
  }

  .navigation-main label.hamburger > i:before {
    background-color: #000000;
  }

  .content-navigation-slide {
    background-color: #fff;
    outline: #d1d1d1 solid 0.5px;
  }

  .profile-picture {
    text-align: center;
  }

  .profile-picture-img {
    width: 100px !important;
    background-size: contain;
    border-radius: 50px;
    height: 100px;
    border-style: solid;
    border-color: #fff;
    margin-top: -50px;
  }

  .profile-details {
    text-align: center;
    display: inline-grid;
  }

  .profile-details-label {
    color: #000;
  }

  .profile-details-logoff {
    color: #000;
    padding-top: 12px;
  }

  .line-item {
    border-bottom-style: solid;
    border-color: #d3d2d2;
    border-width: 1px;
    padding-bottom: 10px;
    text-align: -webkit-center;
    background-color: #fff;
  }

  .navigation-menu {
    overflow-y: hidden;
    height: 60vh;
    outline: #d1d1d1 solid 0.5px;
  }

  //แนวตั้ง mobile
  @media only screen and (max-device-width: 1024px) and (orientation: portrait) {
    .navigation-container-pc {
      display: none;
    }

    .navigation-container-mobile {
      display: flex;
    }

    .navigation-profile-container .navigation-profile-details a {
      color: rgb(0, 0, 0, 85%);
      display: block;
      text-decoration: none;
      cursor: pointer;
    }

    .navigation-profile-container .navigation-profile-details {
      vertical-align: middle;
      padding-right: 3px;
      padding-left: 3px;
      padding-top: 0%;
      width: calc(100% - 60px);
      overflow: hidden;
      white-space: pre-line;
      line-height: 1;
    }

    .navigation-profile-container .navigation-profile-picture {
      display: table-cell;
      vertical-align: middle;
      width: 60px;
    }
  }

  //แนวตั้ง mobile
  @media only screen and (max-device-width: 1024px) and (orientation: landscape) {
    .navigation-container-pc {
      display: none;
    }

    .navigation-container-mobile {
      display: flex;
    }
  }

  @media only screen and (min-device-width: 1025px) {
    .navigation-container-pc {
      display: flex;
    }

    .navigation-container-mobile {
      display: none;
    }
  }

  //แนวตั้ง mobile
  @media only screen and (max-device-width: 767px) and (orientation: portrait) {
    .drawer-list {
      width: 80%;
    }
  }

  //แนวตั้ง mobile
  @media only screen and (max-device-width: 767px) and (orientation: landscape) {
    .drawer-list {
      width: 80%;
    }
  }
}
</style>

<style scoped lang="scss">
@import '@/style/base/common.scss';
$main-bg-color: #fff;
$font-main-color: #424242;

.notification-badge {
  position: absolute;
  top: 0;
}

.notification-badge-sub {
  filter: none !important;
  z-index: 99;
  margin-top: -10px !important;
}

.badge-package {
  position: relative;
  top: -6px;
  display: inline-block;
  width: 10px;
  height: 10px;
  vertical-align: middle;
  border-radius: 50%;
  background-color: $color-red-52;
}

.color-red-day {
  color: $color-red-52;
}

.package-title {
  display: flex;
  flex-flow: wrap;
  gap: 2px;
  align-items: center;
  color: $color-grey-61;
  font-size: 14px;

  .remaining-day {
    color: #969696;
    font-size: 12px;
  }
}
.package-bee-benefit-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: $color-grey-61;
  font-size: 14px;
  .point-detail {
    display: flex;
    align-items: center;
    gap: 4px;
    flex-wrap: wrap;
    font-size: 12px;
    color: #969696;

    strong {
      color: #636363;
    }
  }
}

.package-info {
  font-size: 12px;
  color: $color-grey-a1;
}

.dotAfter {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.navigation-main {
  box-sizing: border-box;
  background-color: $main-bg-color;
  box-shadow: 0 2px 4px rgb(0, 0, 0, 0.075);
  padding: 0 16px 0 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 92px;
  z-index: 12;

  .navbarcontainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .navmenu {
      font-size: 16px;
      font-weight: 500;
      color: $font-main-color;
      display: flex;
      gap: 8px;
      .menumain {
        padding: 8px;
        border-radius: 8px;
        position: relative;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        img {
          margin-left: 8px;
        }
        &.active {
          color: #f9a601;
          background-color: #fff7e6;
          img.arrowdown {
            filter: invert(73%) sepia(83%) saturate(700%) hue-rotate(353deg)
              brightness(98%) contrast(88%);
          }
        }
        &:hover {
          color: #f9a601;
          background-color: #fff7e6;
          img.arrowdown {
            filter: invert(73%) sepia(83%) saturate(700%) hue-rotate(353deg)
              brightness(98%) contrast(88%);
          }
          .dorpdowncontainer,
          &::before {
            visibility: visible;
            opacity: 1;
            color: $font-main-color;
          }
        }
        &::before {
          content: '';
          width: 100%;
          height: 28px;
          background-color: transparent;
          position: absolute;
          visibility: hidden;
          opacity: 0;
          top: 40px;
          left: 0;
        }
        .dorpdowncontainer {
          visibility: hidden;
          opacity: 0;
          position: absolute;
          padding: 16px 0;
          width: max-content;
          top: 60px;
          left: 0px;
          background-color: #fff;
          border-radius: 12px;
          border: solid 1px #fafafa;
          transition: all 0.3s ease-in-out;
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
          .tab {
            font-size: 14px;
            color: #424242;
            padding: 8px 16px;
            margin: 8px 0;
            background-color: #f5f5f5;
          }
          .list {
            padding: 8px 16px;
            &.createnewreward {
              color: #f9a601;
            }
            &:hover,
            &.active {
              background-color: #fff7e6;
              img {
                filter: invert(73%) sepia(83%) saturate(700%) hue-rotate(353deg)
                  brightness(98%) contrast(88%);
              }
            }
            img {
              margin: 0 16px 0 0;
              position: relative;
              top: -3px;
            }
          }
        }
      }
    }

    .userdetail {
      display: flex;
      align-items: center;
      gap: 16px;
      position: relative;
      padding: 8px;
      border-radius: 12px;
      font-size: 16px;
      font-weight: 500;
      .action {
        cursor: pointer;

        &.not-event {
          pointer-events: none;
        }
      }
      .no-action {
        background-color: #eeeeee;
        pointer-events: none;
      }
      .notiicon {
        width: 36px;
        height: 36px;
        background-color: #eeeeee;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background-color: #e0e0e0;
        }
        &.action {
          cursor: pointer;
        }
      }
      .name {
        color: #a1a5b7;
        font-size: 16px;
        max-width: 140px;
      }
      .profileimagecontainer {
        width: 60px;
        height: 60px;
        border-radius: 10px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .userdetail-action {
        display: flex;
        align-items: center;
        gap: 16px;
        position: relative;
        padding: 8px;
        border-radius: 12px;
        font-size: 16px;
        font-weight: 500;
        &:hover {
          background-color: #fcfcfc;
          .dorpdownusercontainer {
            visibility: visible;
            opacity: 1;
            color: $font-main-color;
          }
        }

        .dorpdownusercontainer {
          visibility: hidden;
          opacity: 0;
          position: absolute;
          padding: 16px 0;
          min-width: 250px;
          width: max-content;
          top: 74px;
          right: 0px;
          background-color: #fff;
          border-radius: 12px;
          border: solid 1px #fafafa;
          transition: all 0.3s ease-in-out;
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
          .tab {
            font-size: 14px;
            color: #424242;
            padding: 8px 16px;
            margin: 8px 0;
            background-color: #f5f5f5;
            cursor: pointer;
            &.logout {
              text-align: center;
              img {
                margin-left: 8px;
              }
            }
          }
          .list {
            cursor: pointer;
            padding: 8px 16px;
            &:hover,
            &.active {
              background-color: #fff7e6;
              img.iconprofile {
                filter: invert(73%) sepia(83%) saturate(700%) hue-rotate(353deg)
                  brightness(98%) contrast(88%);
              }
            }
            img {
              margin: 0 16px 0 0;
              position: relative;
              top: -3px;
            }
          }
        }
      }
      .language {
        font-size: 16px;
        font-weight: 500;
        color: #616161;
        span {
          margin-right: 8px;
        }
      }
    }
    .hamburgermenu {
      transition: all 0.3s ease-in-out;
      padding: 8px;
      cursor: pointer;
      display: none;
      &:hover {
        transition: all 0.3s ease-in-out;
        background-color: #fff7e6;
        border-radius: 10px;
        .mainline {
          transition: all 0.3s ease-in-out;
          background-color: #f9a601;
        }
      }
      .mainline {
        transition: all 0.3s ease-in-out;
        width: 40px;
        height: 5px;
        border-radius: 10px;
        margin: 5px 0px;
        background-color: #616161;
      }
    }
  }
  .package-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .package-detail {
      align-items: center;
      font-size: 14px;
      .package-logo {
        width: 36px;
        height: 36px;
      }
    }
    .sent-info {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #969696;
      .sent-info-img {
        width: 16px;
        height: 16px;
        margin-right: 2px;
      }
    }
  }

  .sidebarcontainer {
    width: 100%;
    max-width: 400px;
    min-height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #ffffff;
    z-index: 5;
    box-shadow: 0 -2px 4px rgb(0, 0, 0, 0.075);
    transition: all 0.3s ease-in-out;
    &.show {
      right: -0;
    }
    &.notshow {
      right: -400px;
    }
    .logo {
      padding: 0 16px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .closebtn {
        cursor: pointer;
      }

      .logo-image {
        width: fit-content;
        height: fit-content;
      }
    }
    .sidebarlist {
      border-top: 1px solid rgb(238, 238, 238);
      height: calc(100vh - 522px);
      position: relative;
      overflow-y: scroll;
      /* width */
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      .sidebaritem {
        .mainsidebaritem {
          font-size: 20px;
          padding: 16px 32px;
          cursor: pointer;
          &:hover,
          &.active {
            color: #f9a601;
            background-color: #fff7e6;
            img.arrowdown {
              filter: invert(73%) sepia(83%) saturate(700%) hue-rotate(353deg)
                brightness(98%) contrast(88%);
            }
          }
        }
        .dropdownsidebaritem {
          height: 0;
          overflow: hidden;
          font-size: 20px;
          background-color: #f8f8f8;
          .list {
            padding: 16px 32px 16px 32px;
            cursor: pointer;
            &:hover,
            &.active {
              color: #f9a601;
              background-color: #fffefa;
              img {
                filter: invert(73%) sepia(83%) saturate(700%) hue-rotate(353deg)
                  brightness(98%) contrast(88%);
              }
            }
          }
          &.dropdownopen {
            height: auto;
          }
        }
      }

      .menuitem {
        position: fixed;
        background-color: #fff;
        width: 100%;
        max-width: 400px;
        box-shadow: 0px -4px 4px #c8c8c81f;
        .list {
          font-size: 20px;
          padding: 16px 32px;
          cursor: pointer;
          &:hover,
          &.active {
            color: #f9a601;
            background-color: #fff7e6;
          }
          img {
            margin-right: 8px;
          }
        }
        .tab {
          background-color: #f5f5f5;
          font-size: 20px;
          padding: 12px;
          &.logout {
            text-align: center;
            padding: 18px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1422px) {
  .navigation-main {
    .navbarcontainer {
      .navmenu {
        gap: 4px;
        font-size: 12px;
        .menumain {
          padding: 8px 4px;
          > div {
            > span {
              line-height: 24px;
            }
          }
        }
      }
      .userdetail {
        gap: 8px;
        font-size: 12px;
        .name {
          font-size: 12px;
        }
        .language {
          font-size: 12px;
        }
        .tab {
          font-size: 12px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1188px) {
  .navigation-main {
    .navbarcontainer {
      .navmenu {
        display: none;
      }
      .userdetail {
        display: none;
      }
      .hamburgermenu {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .navigation-main {
    .sidebarcontainer {
      .sidebarlist {
        height: calc(100vh - 372px);
        .sidebaritem {
          .mainsidebaritem {
            font-size: 14px;
            padding: 8px 16px;
          }
          .dropdownsidebaritem {
            font-size: 14px;
            .list {
              padding: 8px 16px;
              img {
                position: relative;
                top: -3px;
              }
            }
          }
        }

        .menuitem {
          .list {
            font-size: 14px;
            padding: 8px 16px;
            img {
              position: relative;
              top: -3px;
            }
          }
          .tab {
            font-size: 14px;
            padding: 12px;
            &.logout {
              padding: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
