import Vue from 'vue';
import BzbsApi from '@/core/Http/BzbsApi.js';
import Cache from '@/helper/Cache.js';

/**
 * @method apiAnnouncements
 * @method apiAnnouncementsPine
 *  */

export default {
	token: function() {
		return Cache.get('CRMUser').Token;
	},
	config: function() {
		return Vue.bzbsConfig;
	},
	agencyId: function() {
		return Cache.get('CRMUser').AgencyId;
	},
	apiAnnouncements: function(skip, top, search, pin, read) {
		var params = {
			agencyId: this.agencyId(),
			device_app_id: this.config().client_id,
			skip: skip,
			top: top
		};

		if (search) {
			params.title = search;
		}
		if (pin) {
			params.pin = pin;
		}
		if (read) {
			params.read = read;
		}
		var strUrl = this.config().bzbsModuleUrl + '/crmplusannouncement';
		return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
	},
	apiAnnouncementDetail: function(partitionKey, rowKey) {
		var params = {
			agencyId: this.agencyId(),
			device_app_id: this.config().client_id,
			partitionKey: partitionKey,
			rowKey: rowKey
		};
		var strUrl = this.config().bzbsModuleUrl + '/crmplusannouncement/detail';
		return BzbsApi.connectBzbsGet(strUrl, this.token(), params);
	},
	apiAnnouncementUnread: function() {
		var strUrl = this.config().bzbsModuleUrl + '/crmplusannouncement/unread?agencyId=' + this.agencyId();
		return BzbsApi.connectBzbsGet(strUrl, this.token(), null);
	},
};
