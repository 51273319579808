export default {
  filterPackage(packages) {
    if (packages && packages.includes('free')) {
      return 'free-trial'
    } else if (packages == 'ezycoupon') {
      return 'ezycoupon'
    } else if (packages == 'corporate') {
      return 'corporate'
    } else if (packages == 'hiveplus') {
      return 'hiveplus'
    } else if (packages == 'basicplus') {
      return 'basicplus'
    } else if (
      packages == 'basic' ||
      packages == 'basic2990' ||
      packages == 'basic4990' ||
      packages == 'basic6990' ||
      packages == 'basic8990' ||
      packages == 'basic10990' ||
      packages == 'basic12990' ||
      packages == 'basic14990' ||
      packages == 'basic16990' ||
      packages == 'basic18990' ||
      packages == 'basic20990' ||
      packages == 'basic22990'
    ) {
      return 'basic'
    } else if (
      packages == 'premium' ||
      packages == 'premium6990' ||
      packages == 'premium7990' ||
      packages == 'premium9990' ||
      packages == 'premium11990' ||
      packages == 'premium13990' ||
      packages == 'premium15990' ||
      packages == 'premium17990' ||
      packages == 'premium19990' ||
      packages == 'premium21990' ||
      packages == 'premium27990' ||
      packages == 'premium33990'
    ) {
      return 'premium'
    } else {
      return ''
    }
  },
  filterPackageByBasic(packages) {
    if (
      packages == 'basic' ||
      packages == 'basic2990' ||
      packages == 'basic4990' ||
      packages == 'basic6990' ||
      packages == 'basic8990' ||
      packages == 'basic10990' ||
      packages == 'basic12990' ||
      packages == 'basic14990' ||
      packages == 'basic16990' ||
      packages == 'basic18990' ||
      packages == 'basic20990' ||
      packages == 'basic22990'
    ) {
      return true
    } else {
      return false
    }
  },
  filterPackageByPremium(packages) {
    if (
      packages == 'premium' ||
      packages == 'premium6990' ||
      packages == 'premium7990' ||
      packages == 'premium9990' ||
      packages == 'premium11990' ||
      packages == 'premium13990' ||
      packages == 'premium15990' ||
      packages == 'premium17990' ||
      packages == 'premium19990' ||
      packages == 'premium21990' ||
      packages == 'premium27990' ||
      packages == 'premium33990'
    ) {
      return true
    } else {
      return false
    }
  },
}
