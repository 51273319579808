import Vue from 'vue'
import BzbsApi from '@/core/Http/BzbsApi.js'
import Cache from '@/helper/Cache.js'
/**
 * @method postUploadTermandCondition
 * @method postUploadPrivacy
 * @method postPdpaConsent
 * @method getImportConsentLog
 * @method postUploadMarketing
 */
export default {
  config: function () {
    return Vue.bzbsConfig
  },
  token: function () {
    return Cache.get('CRMUser').Token
  },
  agencyId: function () {
    return Cache.get('CRMUser').AgencyId
  },
  postUploadTermandCondition: function (fileTH, fileEN) {
    var params = {
      th: fileTH,
      en: fileEN,
    }

    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusoffice/term?agencyId=' +
      this.agencyId()
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  postUploadPrivacy: function (fileTH, fileEN) {
    var params = {
      th: fileTH,
      en: fileEN,
    }

    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusoffice/privacy?agencyId=' +
      this.agencyId()
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  postPdpaConsent: function (enableStatus) {
    var params = {}

    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusoffice/pdpa?agencyId=' +
      this.agencyId() +
      '&enable=' +
      enableStatus
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
  getImportConsentLog: function (type, top, skip) {
    if (!top) top = 10
    if (!skip) skip = 0

    var strToken = this.token()
    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusoffice/importconsentlog?agencyId=' +
      this.agencyId() +
      '&type=' +
      type +
      '&top=' +
      top +
      '&skip=' +
      skip
    return BzbsApi.connectBzbsGet(strUrl, strToken, null)
  },
  postUploadMarketing: function (fileTH, fileEN) {
    var params = {
      th: fileTH,
      en: fileEN,
    }

    var strUrl =
      this.config().bzbsModuleUrl +
      '/crmplusoffice/marketing?agencyId=' +
      this.agencyId()
    return BzbsApi.connectBzbsPost(strUrl, this.token(), params)
  },
}
